import { get } from "lodash";
import * as React from "react";
const { useState, useRef, useEffect } = React;
import * as Modal from "react-modal";

import { Checkbox } from "../../shared/components/Form/Checkbox";
import { SigninAsModal } from "../../shared/components/Register/signin";
import { ITokenDetails } from "../../shared/graphql/organizations";
import {
    Maybe,
    Token,
    TokenTypeEnum,
    UpdateUserPasswordInput,
    useGenerateTokenMutation,
} from "../../shared/models/types";
import { parseJwt } from "../../shared/services/auth";
import { passthrough, passthroughBoolean, TextFormField } from "../shared/components/form";
import { NewOrganizationLocation } from "../shared/routes/locations";
import { userSuperUpdateUserPasswordMutation, useSuperOrganizationsLazy, useSuperUsersLazy } from "./query";

const OrganizationTokenInvitation = (props: { token: Token }) => {
    const location: Location = (window as any).location;
    const url = [
        location.protocol,
        "//",
        location.host,
        location.pathname,
        "#",
        NewOrganizationLocation.toUrl({ key: props.token.key }),
    ].join("");
    return (
        <div>
            <p>Click on this link to create a new organization: {url}</p>
        </div>
    );
};

enum ModalStates {
    CLOSED,
    SHOW_PASSWORD_RESET,
}

export const SettingsPage = () => {
    const [tokenDetails, setTokenDetails] = useState<ITokenDetails>({});
    const [newToken, setNewToken] = useState<Maybe<Token>>(null);
    const nameRef = useRef(null);

    const [generateToken, { data, loading, error }] = useGenerateTokenMutation();

    const createToken = async (event?: React.FormEvent<HTMLFormElement>) => {
        if (event) {
            event.preventDefault();
        }
        const {
            data: {
                generateToken: { token },
            },
        } = await generateToken({
            variables: {
                type: TokenTypeEnum.Organization,
                details: tokenDetails,
            },
        });
        setNewToken(token as Token);
    };

    const [superJwt, setSuperJwt] = useState(null);
    const [role, setRole] = useState(null);
    const [loadUsers, { called: calledSU, loading: loadingSU, data: dataSU }] = useSuperUsersLazy(superJwt);
    const users = dataSU?.users?.nodes || [];

    const [loadOrganizations, { data: dataOrgs }] = useSuperOrganizationsLazy(superJwt);
    const organizations = dataOrgs?.organizations?.nodes || [];

    useEffect(() => {
        if (superJwt) {
            loadUsers();
            loadOrganizations();
            setRole(get(parseJwt(superJwt), "role", "author"));
        }
    }, [superJwt]);

    const [messages, setMessages] = useState("");
    const [updateUserPasswordMutation] = userSuperUpdateUserPasswordMutation(superJwt);
    const [selectedUser, setSelectedUser] = useState(null);
    const [modalState, setModalState] = useState(ModalStates.CLOSED);
    const handlePasswordReset = () => {
        if (selectedUser === null) {
            return;
        }
        setModalState(ModalStates.SHOW_PASSWORD_RESET);
    };
    const [resetDetails, setResetDetails] = useState<UpdateUserPasswordInput>({
        password: "",
        username: "",
    });
    const doPasswordReset = async (event?: React.FormEvent<HTMLFormElement>) => {
        if (event) {
            event.preventDefault();
        }
        // Confirm username matches
        if (selectedUser.username !== resetDetails.username) {
            setMessages("Username did not match with selected user.");
            return;
        }

        // Do it
        const result = await updateUserPasswordMutation({ variables: { input: resetDetails } });
        const errMsg = get(result, "errors[0].message", false);
        if (errMsg) {
            setMessages(errMsg);
        } else {
            setMessages("");
            setModalState(ModalStates.CLOSED);
        }
    };

    useEffect(() => {
        setMessages("");
    }, [modalState, selectedUser]);

    if (!!!superJwt) {
        return (
            <div>
                <SigninAsModal skipSaveSession={true} responseHandler={setSuperJwt} role={"super"} />
            </div>
        );
    }

    return (
        <div>
            <Modal isOpen={modalState === ModalStates.SHOW_PASSWORD_RESET} ariaHideApp={false}>
                <div className={"w-100 pa2 ph3-ns pb3-ns"}>
                    <div className="w-100">
                        Editing user &quot;{selectedUser?.username}&quot;
                        <form onSubmit={doPasswordReset} className={"dark-gray w-100 w-75-l center"}>
                            <TextFormField
                                name="username"
                                value={resetDetails.username}
                                placeholder="Confirm username:"
                                inputRef={nameRef}
                                onChange={passthrough(resetDetails, setResetDetails)}
                                maxLength={120}
                            />
                            <TextFormField
                                name="password"
                                type="password"
                                value={resetDetails.password}
                                placeholder="New Password"
                                onChange={passthrough(resetDetails, setResetDetails)}
                                maxLength={20}
                            />
                            <input
                                className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib mr1"
                                type="submit"
                                value="Set New User Password"
                            />
                            <input
                                className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib mr1"
                                type="button"
                                value="Cancel"
                                onClick={() => setModalState(ModalStates.CLOSED)}
                            />
                            <p>{messages}</p>
                        </form>
                    </div>
                </div>
            </Modal>
            <article className={"dark-gray w-100 w-75-l center"} hidden={role !== "super"}>
                <h2>Users ({users.length})</h2>
                <div className="pa2">
                    <input
                        type="button"
                        value="Reset Password"
                        onClick={handlePasswordReset}
                        className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib mr1"
                    />
                </div>
                <table className="collapse ba br2 b--black-10 pv2 ph3">
                    <tbody>
                        {users.map((user) => {
                            return (
                                <tr key={`user-${user.id}`} className="striped--light-gray ">
                                    <td className="pv2 ph3">
                                        <input
                                            type="checkbox"
                                            checked={selectedUser?.id === user.id}
                                            onChange={(e) => {
                                                if (e.currentTarget.checked) {
                                                    setSelectedUser(user);
                                                } else {
                                                    setSelectedUser(null);
                                                }
                                            }}
                                        />
                                    </td>
                                    <td className="pv2 ph3">{user.id}</td>
                                    <td className="pv2 ph3">{user.username}</td>
                                    <td className="pv2 ph3">{user.createdAt}</td>
                                    <td className="pv2 ph3">{user.updatedAt}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </article>
            <article className={"dark-gray w-100 w-75-l center"}>
                <h2>Create an Organization</h2>
                <div className={"w-100 pa2 ph3-ns pb3-ns"}>
                    <div className="w-100">
                        <form onSubmit={createToken} className={"dark-gray w-100 w-75-l center"}>
                            <TextFormField
                                name="name"
                                value={tokenDetails.name}
                                placeholder="Organization Name"
                                inputRef={nameRef}
                                onChange={passthrough(tokenDetails, setTokenDetails)}
                                maxLength={50}
                            />
                            <TextFormField
                                name="url"
                                value={tokenDetails.url}
                                placeholder="Organization Short Code"
                                onChange={passthrough(tokenDetails, setTokenDetails)}
                                maxLength={50}
                            />
                            <div className="pv2">
                                <Checkbox
                                    label="Editable"
                                    name="editable"
                                    checked={!!tokenDetails.editable}
                                    onChange={passthroughBoolean(tokenDetails, setTokenDetails)}
                                    value="editable"
                                />
                            </div>
                            <input
                                className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib mr1"
                                type="submit"
                                value="Create New Organization Invitation"
                            />
                        </form>
                        {newToken && <OrganizationTokenInvitation token={newToken} />}
                    </div>
                </div>
            </article>
            <article className={"dark-gray w-100 w-75-l center"} hidden={role !== "super"}>
                <h2>Organizations ({organizations.length})</h2>
                <table className="collapse ba br2 b--black-10 pv2 ph3">
                    <tbody>
                        {organizations.map((org) => {
                            return (
                                <React.Fragment key={`fragment-${org.id}`}>
                                    <tr key={`org-${org.id}`} className="striped--light-gray ">
                                        <td className="pv2 ph3">{org.id}</td>
                                        <td className="pv2 ph3">{org.name}</td>
                                        <td className="pv2 ph3">{org.url}</td>
                                        <td className="pv2 ph3">{org.createdAt}</td>
                                        <td className="pv2 ph3">{org.updatedAt}</td>
                                    </tr>
                                    {org.tokensByOrgId.nodes.map((token) => {
                                        return (
                                            <tr key={`token-${token.id}`} className="striped--light-gray ">
                                                <td className="pv2 ph3">t:{token.type}</td>
                                                <td className="pv2 ph3">{JSON.stringify(token.details, null, 2)}</td>
                                                <td className="pv2 ph3">{token.state}</td>
                                                <td className="pv2 ph3">{token.createdAt}</td>
                                                <td className="pv2 ph3">{token.updatedAt}</td>
                                            </tr>
                                        );
                                    })}
                                    {org.membershipsByOrgId.nodes.map((membership) => {
                                        return (
                                            <tr key={`membership-${membership.id}`} className="striped--light-gray ">
                                                <td className="pv2 ph3">m:{membership.type}</td>
                                                <td className="pv2 ph3">{membership.user.username}</td>
                                                <td className="pv2 ph3">{membership.state}</td>
                                                <td className="pv2 ph3">{membership.createdAt}</td>
                                                <td className="pv2 ph3">{membership.updatedAt}</td>
                                            </tr>
                                        );
                                    })}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </article>
        </div>
    );
};
