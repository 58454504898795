import { gql, makeVar, useReactiveVar } from "@apollo/client";
import { findIndex } from "lodash";
import * as moment from "moment";
import {
    OrganizationsNotesQuery,
    PersonalNotesQuery,
    CreateNoteMutationVariables,
    NoteAccessEnum,
    QueryNoteQuery,
    UpdateNoteMutationVariables,
    useOrganizationsNotesQuery,
    usePersonalNotesQuery,
    Note as BaseNote,
} from "../models/types";
import { ICurrentUser } from "../services/auth.data";
import { getCurrentLocalDate, getLocalDate } from "../utils/formatting";
import { useCreateNote, useUpdateNote } from "./query";

export const CreateNote = gql`
    mutation CreateNote(
        $orgId: Int
        $access: NoteAccessEnum
        $name: String!
        $type: String!
        $payload: JSON!
        $meta: JSON!
        $isPublic: Boolean!
        $userId: Int!
        $slug: String!
    ) {
        payload: createNote(
            input: {
                note: {
                    orgId: $orgId
                    access: $access
                    name: $name
                    payload: $payload
                    meta: $meta
                    isPublic: $isPublic
                    userId: $userId
                    type: $type
                    slug: $slug
                }
            }
        ) {
            note {
                __typename
                id
                userId
                orgId
                type
                name
                slug
                payload
                meta
                isPublic
                createdAt
                updatedAt
            }
        }
    }
`;

export const UpdateNote = gql`
    mutation UpdateNote(
        $id: Int!
        $name: String
        $type: String
        $slug: String
        $payload: JSON
        $meta: JSON
        $isPublic: Boolean
        $userId: Int
        $deletedAt: Datetime
    ) {
        payload: updateNote(
            input: {
                id: $id
                patch: {
                    name: $name
                    type: $type
                    slug: $slug
                    payload: $payload
                    meta: $meta
                    isPublic: $isPublic
                    userId: $userId
                    deletedAt: $deletedAt
                }
            }
        ) {
            note {
                __typename
                id
                userId
                orgId
                type
                name
                slug
                payload
                meta
                isPublic
                createdAt
                updatedAt
                deletedAt
            }
        }
    }
`;

export const AllNotes = gql`
    query AllNotes {
        notes(orderBy: UPDATED_AT_DESC) {
            nodes {
                __typename
                id
                userId
                orgId
                type
                name
                slug
                payload
                meta
                isPublic
                createdAt
                updatedAt
            }
        }
    }
`;

export const PersonalNotes = gql`
    query PersonalNotes($type: String, $slug: String, $isPublic: Boolean, $userId: Int) {
        notes(
            orderBy: UPDATED_AT_DESC
            condition: { orgId: null, type: $type, slug: $slug, isPublic: $isPublic, userId: $userId }
        ) {
            nodes {
                __typename
                id
                userId
                orgId
                type
                name
                slug
                payload
                meta
                isPublic
                createdAt
                updatedAt
            }
        }
    }
`;

export const QueryNote = gql`
    query QueryNote($id: Int!) {
        note: note(id: $id) {
            __typename
            id
            userId
            orgId
            type
            name
            slug
            payload
            meta
            isPublic
            createdAt
            updatedAt
        }
    }
`;

export const QueryNoteVersions = gql`
    query NoteVersions($type: String, $slug: String, $isPublic: Boolean, $noteId: Int) {
        noteVersions(
            orderBy: UPDATED_AT_DESC
            condition: { orgId: null, type: $type, slug: $slug, isPublic: $isPublic, noteId: $noteId }
        ) {
            nodes {
                __typename
                id
                noteId
                editorId
                userId
                orgId
                type
                name
                slug
                payload
                meta
                isPublic
                createdAt
                updatedAt
            }
        }
    }
`;

export const NoteAtVersion = gql`
    query NoteAtVersion($id: Int!, $versionId: Int!) {
        note: note(id: $id) {
            __typename
            id
            userId
            orgId
            type
            name
            slug
            payload
            meta
            isPublic
            createdAt
            updatedAt
            atVersion(input: { versionId: $versionId }) {
                id
                userId
                orgId
                type
                name
                slug
                payload
                meta
                isPublic
                createdAt
                updatedAt
            }
        }
    }
`;

type Note = Partial<PersonalNotesQuery["notes"]["nodes"][0]> &
    Partial<QueryNoteQuery["note"]> &
    Partial<CreateNoteMutationVariables> &
    Partial<BaseNote>;
type Organization = Partial<OrganizationsNotesQuery["organizations"]["nodes"][0]>;

export const personalNotesVar = makeVar([] as IByDate[]);
export const organizationVar = makeVar<Organization>(null);
export const organizationNotesVar = makeVar([] as IByDate[]);

export const useCurrentNotes = (code: string) => {
    const personalNotes = useReactiveVar(personalNotesVar);
    const orgNotes = useReactiveVar(organizationNotesVar);
    const organization = useReactiveVar(organizationVar);

    const { loading, error, data: personalNotesData, refetch: refetchPersonalNotes } = usePersonalNotesQuery({
        skip: !!code,
        onCompleted: (nData) => {
            if (nData?.notes?.nodes) {
                const notesByDate = groupNotesByDate(nData.notes.nodes);
                personalNotesVar(notesByDate);
            }
        },
    });

    const { loading: loadingOrg, data: orgData, refetch: refetchOrgNotes } = useOrganizationsNotesQuery({
        skip: !!!code,
        variables: { url: code },
        onCompleted: (nData) => {
            const org = nData?.organizations?.nodes[0];
            if (org) {
                const org = nData.organizations.nodes[0];
                const notesByDate = groupNotesByDate(org.notes.nodes);
                organizationNotesVar(notesByDate);
                organizationVar(org);
            }
        },
    });

    return {
        notesByDate: code ? orgNotes : personalNotes,
        refetchNotes: async () => {
            return;
            if (code) {
                const { data: nData } = await refetchOrgNotes();
                const org = nData?.organizations?.nodes[0];
                if (org) {
                    const org = nData.organizations.nodes[0];
                    const notesByDate = groupNotesByDate(org.notes.nodes);
                    organizationNotesVar(notesByDate);
                    organizationVar(org);
                }
            } else {
                const { data: nData } = await refetchPersonalNotes();
                if (nData?.notes?.nodes) {
                    const notesByDate = groupNotesByDate(nData.notes.nodes);
                    personalNotesVar(notesByDate);
                }
            }
        },
        error,
        loading,
        organization,
    };
};

export interface IByDate {
    date: string;
    notes: Note[];
}

export interface IMeta {
    ed: IEditedDateStats[];
    cb: number;
    cbx: number;
    tags: string[];
    wc: number;
}

export interface IEditedDateStats {
    d: string; // String in local time
    wc: number; // length of content on last edit on date
    ad: number; // added
    rm: number; // removed
}

const getEd = (note: Note) => {
    // simulate if necessary
    const meta = note.meta as IMeta;
    const ed = [...((note.meta as IMeta).ed || [])];
    if (ed.length === 0) {
        // generate if an old note
        const cAt = getLocalDate(note.createdAt),
            uAt = getLocalDate(note.updatedAt);
        ed.push({ d: cAt, wc: 0, ad: 0, rm: 0 });
        if (cAt !== uAt) {
            ed.push({ d: uAt, wc: 0, ad: 0, rm: 0 });
        }
    }
    return ed;
};

const groupNotesByDate = (notes: Note[]) => {
    const groupedByDateHash: { [k: string]: IByDate } = {};

    for (const note of notes) {
        const ed = getEd(note);
        for (const dStats of ed) {
            if (!!!groupedByDateHash[dStats.d]) {
                groupedByDateHash[dStats.d] = { date: dStats.d, notes: [] };
            }
            groupedByDateHash[dStats.d].notes.push(note);
        }
    }

    const groupedByDate: IByDate[] = [];
    const datesWithEdits = Object.keys(groupedByDateHash);
    datesWithEdits.sort();
    for (let index = datesWithEdits.length - 1; index >= 0; index--) {
        const ed = datesWithEdits[index];
        groupedByDate.push(groupedByDateHash[ed]);
    }

    return groupedByDate;
};

export const updateMeta = (note: Note) => {
    const currentLocalDate = getCurrentLocalDate();
    const wc = note.payload?.md?.length || 0;
    let newMeta: IMeta;
    if (!!!note.createdAt) {
        // new note
        newMeta = {
            ed: [{ d: currentLocalDate, wc, ad: wc, rm: 0 }],
            cb: 0,
            cbx: 0,
            tags: [],
            wc: note.payload?.md?.length || 0,
        };
    } else {
        // note being updated
        const noteMetaEd = getEd(note);
        const cldIndex = findIndex(noteMetaEd, { d: currentLocalDate });
        if (cldIndex === -1) {
            newMeta = {
                ed: [...noteMetaEd, { d: currentLocalDate, wc, ad: wc, rm: 0 }],
                cb: 0,
                cbx: 0,
                tags: [],
                wc,
            };
        } else {
            const newEd = [...noteMetaEd];
            newEd[cldIndex] = { d: currentLocalDate, wc, ad: 0, rm: 0 };
            newMeta = {
                ed: newEd,
                cb: 0,
                cbx: 0,
                tags: [],
                wc,
            };
        }
    }

    return {
        ...note,
        meta: newMeta,
    };
};

export const useUpdateOrCreateNote = (user: ICurrentUser, organization: Organization) => {
    const [createNote] = useCreateNote({ code: organization?.url });
    const [updateNote] = useUpdateNote({ code: organization?.url });

    const updateOrCreateNote = async (note: Partial<Note>, name: string, markdown: string) => {
        let _note;
        if (note && note.id) {
            const {
                data: { payload },
            } = await updateNote({
                variables: updateMeta({
                    ...note,
                    userId: user.id,
                    name,
                    payload: {
                        md: markdown,
                    },
                }) as UpdateNoteMutationVariables,
            });
            _note = payload.note;
        } else {
            const {
                data: { payload },
            } = await createNote({
                variables: updateMeta({
                    ...note,
                    userId: user.id,
                    name,
                    orgId: organization?.id,
                    access: organization ? NoteAccessEnum.Semipublic : NoteAccessEnum.Private,
                    payload: {
                        md: markdown,
                    },
                }) as CreateNoteMutationVariables,
            });
            _note = payload.note;
        }
        return _note;
    };
    return [updateOrCreateNote];
};
interface IQueryFilterData {
    dd: { [id: number]: Note };
}
function escapeRegExp(str: string) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}
export const useQueryFilter = (q: string) => {
    const queryFilterData: IQueryFilterData = { dd: {} };
    const queryFilter = (note: Note) => {
        if (q.trim().length === 0) {
            return note;
        }
        //parse string
        const parts = q.split(" ");
        const words = [];
        const commands = [];
        for (const p of parts) {
            if (p.startsWith("/")) {
                commands.push(p);
            } else {
                words.push(p);
            }
        }
        for (const cmd of commands) {
            if (cmd === "/dd") {
                if (!!queryFilterData.dd[note.id]) {
                    return;
                } else {
                    queryFilterData.dd[note.id] = note;
                }
            }
        }
        for (const word of words) {
            if (word.length === 0) {
                continue;
            }
            const safeWord = escapeRegExp(word);
            const reWord = new RegExp(safeWord, "i");
            const titleResult = note.name.match(reWord);
            const bodyResult = note.payload.md.match(reWord);
            if (!!!titleResult && !!!bodyResult) {
                return;
            }
        }
        return note;
    };
    return queryFilter;
};

export const useDeleteNote = (code: string) => {
    const [updateNote] = useUpdateNote({ code });
    const deleteNote = async (note: Note) => {
        const {
            data: { payload },
        } = await updateNote({
            variables: {
                id: note.id,
                deletedAt: moment.utc().format(),
            } as any,
        });
        return payload.note;
    };
    return [deleteNote];
};
