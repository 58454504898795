import cx from "classnames";
import * as React from "react";
const style = require("./style.scss");

interface IProps {
    label: string;
    checked?: boolean;
    type?: string;
    value?: string;
    onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
    name: string;
    required?: boolean;
}

export const Checkbox = ({
    label,
    checked,
    value,
    onChange,
    name,
    required,
    type = "checkbox",
}: IProps) => (
    <div className={cx(style.root, "flex items-center")}>
        <input
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            checked={checked}
            required={required}
            id={value}
            className={style.checkbox}
        />
        <label htmlFor={value}>{label}</label>
    </div>
);
