import {
    ApolloClient,
    ApolloLink,
    createHttpLink,
    InMemoryCache,
    makeVar,
    Operation,
    useReactiveVar,
} from "@apollo/client";
// tslint:disable-next-line:no-submodule-imports
import { setContext } from "@apollo/client/link/context";
// tslint:disable-next-line:no-submodule-imports
import { onError } from "@apollo/client/link/error";

import { AuthenticateUser, RegisterUser } from "./components/Register/query.gql";

import { EntryType, getAuthState, handleExpiredAuth, parseJwt, setHeaders, tokenName } from "./services/auth";
import { currentUserVar } from "./services/auth.data";

let client: ApolloClient<Record<string, unknown>> = null;

export const setupApolloClient = (config: { graphqlUrl: string; entry?: EntryType }) => {
    const httpLink = createHttpLink({
        uri: config.graphqlUrl,
    });
    const authLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        // const token = localStorage.getItem("token");
        const token = localStorage.getItem(tokenName(config.entry));
        if (_.query === AuthenticateUser || _.query === RegisterUser) {
            return { headers };
        }
        // return the headers to the context so httpLink can read them
        if (!!!token) {
            return { headers };
        }
        return {
            headers: {
                ...headers,
                authorization: `Bearer ${token}`,
            },
        };
    });

    const cache = new InMemoryCache({
        addTypename: false,
        dataIdFromObject: (obj: { nodeId: string }) => obj.nodeId || null,
        typePolicies: {
            Query: {
                fields: {},
            },
        },
    });

    client = new ApolloClient({
        cache,
        link: ApolloLink.from([onError(handleExpiredAuth), authLink, httpLink]),
    });

    const authState = getAuthState(config.entry);
    if (authState.currentUser) {
        currentUserVar(authState.currentUser);
    }

    (window as any).__APOLLO_CLIENT__ = client;

    client.defaultOptions = {
        mutate: {
            errorPolicy: "all",
        },
    };

    return client;
};

export const getApolloClient = () => {
    return client;
};

let superJwt: string = null;
let superClient: ApolloClient<Record<string, any>> = null;
export const setupSuperApolloClient = (config: { graphqlUrl: string; entry?: EntryType }) => {
    const httpLink = createHttpLink({
        uri: config.graphqlUrl,
    });
    const authLink = setContext((_, { headers }) => {
        const token = superJwt;
        // return the headers to the context so httpLink can read them
        if (!!!token) {
            return { headers };
        }
        return {
            headers: {
                authorization: `Bearer ${token}`,
                ...headers,
            },
        };
    });

    const cache = new InMemoryCache({
        addTypename: false,
        dataIdFromObject: (obj: { nodeId: string }) => obj.nodeId || null,
        typePolicies: {
            Query: {
                fields: {},
            },
        },
    });

    superClient = new ApolloClient({
        cache,
        link: ApolloLink.from([onError(handleExpiredAuth), authLink, httpLink]),
    });

    superClient.defaultOptions = {
        mutate: {
            errorPolicy: "all",
        },
    };

    return superClient;
};

export const useSuperApolloClient = (_superJwt: string) => {
    superJwt = _superJwt;
    return superClient;
};
