import { ApolloCache, DocumentNode, useMutation, useQuery } from "@apollo/client";

import { findIndex } from "lodash";
import {
    AllNotesQuery,
    AllNotesQueryVariables,
    CreateNoteMutation,
    CreateNoteMutationVariables,
    QueryNoteQuery,
    QueryNoteQueryVariables,
    UpdateNoteMutation,
    UpdateNoteMutationVariables,
} from "../models/types";
export { Note } from "../models/types";
import { AllNotes, PersonalNotes, CreateNote, QueryNote, UpdateNote } from "./notes";
import { OrganizationsNotes } from "./organizations";

export const useCreateNote = ({ code }: { code?: string } = {}) => {
    const mutation = useMutation<CreateNoteMutation, CreateNoteMutationVariables>(CreateNote, {
        errorPolicy: "all",
        update: (cache, { data }) => {
            if (!data) {
                return;
            }
            const note = data.payload.note;
            if (code) {
                updateOrganizationNotes(cache, note, OrganizationsNotes, code, true);
            } else {
                QUERIES_WITH_NOTES_TO_UPDATE.map((query) => updateNotes(cache, note, query, true));
            }
        },
    });
    return mutation;
};

const updateNotes = <Query extends { notes?: { nodes: any[] } }>(
    cache: ApolloCache<any>,
    note: { id: number; deletedAt?: string },
    query: DocumentNode,
    push = false, // adds to the list as a new item
) => {
    const notesData: Query = cache.readQuery({ query });

    if (!!!notesData) {
        return;
    }

    const previousIndex = findIndex(notesData.notes.nodes, {
        id: note.id,
    });
    if (!!!push && previousIndex === -1) {
        return;
    }

    const newNotesData = {
        ...notesData,
        notes: {
            nodes: notesData.notes.nodes.concat(),
        },
    };

    if (!!!push) {
        newNotesData.notes.nodes.splice(previousIndex, 1);
    }
    if (!!!note.deletedAt) {
        newNotesData.notes.nodes.unshift(note);
    }

    cache.writeQuery({
        query,
        data: newNotesData,
    });

    return notesData;
};

interface OrgNotesQuery {
    organizations: {
        nodes: {
            notes?: { nodes: any[] };
        }[];
    };
}

const updateOrganizationNotes = <Query extends OrgNotesQuery>(
    cache: ApolloCache<any>,
    note: { id: number; deletedAt?: string },
    query: DocumentNode,
    code: string,
    push = false, // adds to the list as a new item
) => {
    if (!!!code) {
        return;
    }
    const organizationsData: Query = cache.readQuery({ query, variables: { url: code } });
    if (!!!organizationsData) {
        return;
    }
    const notesData = organizationsData.organizations.nodes[0];

    if (!!!notesData) {
        return;
    }

    const previousIndex = findIndex(notesData.notes.nodes, {
        id: note.id,
    });
    if (!!!push && previousIndex === -1) {
        return;
    }

    const newNotesData = {
        ...notesData,
        notes: {
            nodes: notesData.notes.nodes.concat(),
        },
    };
    if (!!!push) {
        newNotesData.notes.nodes.splice(previousIndex, 1);
    }
    if (!!!note.deletedAt) {
        newNotesData.notes.nodes.unshift(note);
    }

    const newOrganizationData = {
        ...organizationsData,
        organizations: [newNotesData],
    };
    cache.writeQuery({
        query,
        variables: { url: code },
        data: newOrganizationData,
    });

    return notesData;
};

const QUERIES_WITH_NOTES_TO_UPDATE = [AllNotes, PersonalNotes];

export const useUpdateNote = ({ code }: { code?: string } = {}) => {
    const mutation = useMutation<UpdateNoteMutation, UpdateNoteMutationVariables>(UpdateNote, {
        errorPolicy: "all",
        update: (cache, { data }) => {
            if (data) {
                const noteId = data.payload.note.id;
                const updatedNote = data.payload.note;

                QUERIES_WITH_NOTES_TO_UPDATE.map((query) => updateNotes(cache, updatedNote, query));
                updateOrganizationNotes(cache, updatedNote, OrganizationsNotes, code);

                cache.writeQuery({
                    query: QueryNote,
                    variables: { id: noteId },
                    data: {
                        note: updatedNote,
                    },
                });
                return;
            }
        },
    });
    return mutation;
};

export const useAllNotes = () => {
    return useQuery<AllNotesQuery, AllNotesQueryVariables>(AllNotes);
};

export const useNote = (variables: QueryNoteQueryVariables) => {
    return useQuery<QueryNoteQuery, QueryNoteQueryVariables>(QueryNote, {
        variables,
        skip: !!!variables || !!!variables.id,
    });
};
