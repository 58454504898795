import { useReactiveVar } from "@apollo/client";
import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { AuthModal, AuthRoutes } from "../../../shared/services/routeAuth";

import { currentUserVar } from "../../../shared/services/auth.data";
import { Layout } from "../../../shared/utils/routeWithNavigation";
import { EditorPage } from "../../EditorPage";
import { LongformHomepage } from "../../LongformHomepage";
import { MembershipInvitationPage } from "../../MembershipInvitationPage";
import { NewOrganizationPage } from "../../NewOrganizationPage";
import { OrgSettingsPage } from "../../OrgSettingsPage";
import { SettingsPage } from "../../SettingsPage";
import {
    InvitationPage,
    NewNoteLocation,
    NewOrganizationLocation,
    NoteLocation,
    OrganizationLocation,
    OrgNewNoteLocation,
    OrgNoteLocation,
    OrgSettings,
    SettingsLocation,
} from "./locations";

export const AppRoutes = () => {
    return (
        <React.Fragment>
            <AuthModal />
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path={NoteLocation.path} element={<EditorPage />} />
                    <Route path={SettingsLocation.path} element={<SettingsPage />} />
                    <Route path={NewOrganizationLocation.path} element={<NewOrganizationPage />} />
                    <Route path={OrgNoteLocation.path} element={<EditorPage />} />
                    <Route path={OrgNewNoteLocation.path} element={<EditorPage />} />
                    <Route path={OrgSettings.path} element={<OrgSettingsPage />} />
                    <Route path={InvitationPage.path} element={<MembershipInvitationPage />} />
                    <Route path={OrganizationLocation.path} element={<EditorPage />} />
                    <Route path={NewNoteLocation.path} element={<EditorPage />} />
                    <Route index element={<LongformHomepage />} />
                </Route>
            </Routes>
        </React.Fragment>
    );
};

export const OpenRoutes = () => {
    return [
        <Route key="no" path={NewOrganizationLocation.path} element={<NewOrganizationPage />} />,
        <Route key="ip" path={InvitationPage.path} element={<MembershipInvitationPage />} />,
    ];
};

export const App = () => {
    const user = useReactiveVar(currentUserVar);
    return <div>{!!user ? <AppRoutes /> : <AuthRoutes Homepage={LongformHomepage} openRoutes={OpenRoutes} />}</div>;
};
