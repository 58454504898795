import * as React from "react";

import { ApolloProvider } from "@apollo/client";
import * as ReactDOM from "react-dom";
import { HashRouter as Router } from "react-router-dom";

import { EntryType } from "../shared/services/auth";

// Routes
import { App } from "./shared/routes";

// Config
import { setupApolloClient, setupSuperApolloClient } from "../shared/client";

// This import is resolved with special rules defined in our Webpack config.
// @ts-ignore
import * as config from "@membright/config";

// tslint:disable-next-line
require("tachyons/css/tachyons.min.css");
import "./index.css";

const ENTRY = (window as any).__ENTRY__ as EntryType;
const client = setupApolloClient({ ...config, entry: ENTRY });
setupSuperApolloClient({ ...config, entry: ENTRY });

const rootElement = document.getElementById("root");

ReactDOM.render(
    <ApolloProvider client={client}>
        <Router>
            <App />
        </Router>
    </ApolloProvider>,
    rootElement,
);

if ((module as any).hot) {
    (module as any).hot.accept("./shared/routes", () => {
        const { App } = require("./shared/routes");

        ReactDOM.render(
            <ApolloProvider client={client}>
                <Router>
                    <App />
                </Router>
            </ApolloProvider>,
            rootElement,
        );
    });
}
