import { get } from "lodash";
import * as moment from "moment";
import * as React from "react";
import { Link } from "react-router-dom";
import { Note } from "../../shared/graphql/query";

export interface IHasNavLinks {
    urlEdit: (nodeId?: number) => { pathname: string; search: string; hash?: string };
    urlMain: (nodeId?: number) => string | { pathname: string; search: string; hash?: string };
    urlCreate: () => { pathname: string; search: string; hash?: string };
}

interface IProps extends IHasNavLinks {
    note: Note;
    selected?: boolean;
    onClick?: () => void;
}

export const NoteRow = (props: IProps) => {
    const { note, selected, onClick, urlEdit, urlMain } = props;
    return (
        <article key={note.id} className="dt w-100 bb b--black-05 pb2 mt2" onClick={onClick}>
            <div className="dtc w1 w2-ns v-mid tr">{selected && <span>&#62;</span>}</div>
            <Link className="dtc v-mid pl3 no-underline" to={urlMain(note.id)}>
                {note.name ? (
                    <h1 className="f6 f5-ns fw6 lh-title black mv0">{note.name}</h1>
                ) : (
                    <h1 className="f6 f5-ns fw6 lh-title black-30 mv0">Unnamed</h1>
                )}
                <h2 className="f6 fw4 mt0 mb0 black-60">
                    {note.meta.cb > 0 && (
                        <span>
                            cb: {note.meta.cbx}/{note.meta.cb}{" "}
                        </span>
                    )}
                    ed: {get(note, "meta.ed.length", 0)} wc: {note.meta.wc} created:{" "}
                    {moment.utc(note.createdAt).format("M/D/Y")}
                </h2>
            </Link>
            {/* <div className="dtc v-mid pr1">
                <form className="w-100 tr">
                    <Link
                        className="f6 button-reset bg-white ba b--black-10 dim pointer pa1 black-60 no-underline nowrap"
                        to={urlEdit(note.id)}>
                        &#9999; Edit
                    </Link>
                </form>
            </div> */}
        </article>
    );
};
