import * as React from "react";
const { useEffect, useState, useRef, useMemo } = React;

import * as Modal from "react-modal";
import { useReactiveVar } from "@apollo/client";
import { DOMEvent, Editor, KeyMap } from "codemirror";
import * as moment from "moment";
import { configure as configureHotKeys, HotKeys } from "react-hotkeys";
import SimpleMDEEditor, { SimpleMdeToCodemirrorEvents } from "react-simplemde-editor";
import { useNavigate, useParams } from "react-router-dom";
import cx from "classnames";

import { Note, useCreateNote, useNote, useUpdateNote } from "../../shared/graphql/query";
import {
    CreateNoteMutationVariables,
    NoteAccessEnum,
    NoteVersion,
    UpdateNoteMutationVariables,
    useNoteAtVersionQuery,
    useNoteVersionsQuery,
} from "../../shared/models/types";
import { alertsVar, currentUserVar } from "../../shared/services/auth.data";
import { getNewNoteUrl, getNoteUrl } from "../shared/routes/locations";
import { NoteRow } from "./NoteRow";
import { filter, find, get } from "lodash";
import {
    IByDate,
    useCurrentNotes,
    useUpdateOrCreateNote,
    useQueryFilter,
    useDeleteNote,
} from "../../shared/graphql/notes";
import { getLocalDate, getLocalTime } from "../../shared/utils/formatting";
import { usePrompt } from "../../shared/utils/blockNavigation";
import { useOnScrollIntoView } from "../../shared/utils/onScrollIntoView";
import { toInt } from "../../shared/models/content";
import { useStateWithCallbackLazy } from "../../shared/utils/useStateWithCallback";
import { useHotkeys } from "react-hotkeys-hook";

const constructNote = (): Partial<Note> => {
    return {
        type: "note",
        name: "",
        payload: { md: "" },
        meta: { cbx: 0, cb: 0, ed: [], wc: 0, tags: [] },
        isPublic: false,
        slug: "",
    };
};

enum ModalStates {
    CLOSED,
    SHOW_NOTE_VERSIONS,
    DELETE,
}

export const EditorPage = () => {
    const { id, code } = useParams();
    const [markdown, setMarkdown] = useState("");
    const [name, setName] = useState("");
    const [note, setNote] = useStateWithCallbackLazy(constructNote());
    const [failure, setFailure] = useState({ count: 0, message: "" });
    const nameRef = useRef(null);
    const navigate = useNavigate();

    const user = useReactiveVar(currentUserVar);

    const { notesByDate, error, refetchNotes, organization } = useCurrentNotes(code);
    const [updateOrCreateNote] = useUpdateOrCreateNote(user, organization);

    const alerts = useReactiveVar(alertsVar);
    if (error && alerts.length === 0) {
        refetchNotes();
    }

    const { loading: loadingNote, data: noteData } = useNote({ id: Number(id) });
    // TODO: make a "set note" that sets all fields
    if (id && noteData && noteData.note && noteData.note.id !== note.id) {
        setNote(noteData.note);
        setMarkdown(noteData.note.payload.md);
        setName(noteData.note.name);
    }
    useEffect(() => {
        if (!!!id || toInt(id) !== note?.id) {
            setNote(constructNote());
            setMarkdown("");
            setName("");
            if (!!!id) {
                nameRef.current.focus();
            }
            window.scrollTo(0, 0);
        }
    }, [id, note?.id]);

    const isUnsaved = () => {
        if (!!!note.id && markdown === "" && note.name === name) {
            return false;
        }
        return !!!note.id || note.payload.md !== markdown || note.name !== name;
    };
    const CONFIRMATION_MESSAGE = "Unsaved changes. Are you sure you want to lose these changes?";
    usePrompt(CONFIRMATION_MESSAGE, isUnsaved());

    const saveAndClose = () => {
        // TODO: save and change URL to rendered note
    };

    const [deleteNote] = useDeleteNote(code);
    const save = async (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();
        const updatedNote = await updateOrCreateNote(note, name, markdown);
        setNote(updatedNote, (nn: any) => {
            if (!!!id) {
                navigate(getNoteUrl({ code, id: updatedNote.id }));
            }
        });
        refetchNotes();
    };
    const allwaysSave = useRef(null);
    allwaysSave.current = save;

    const [modalState, setModalState] = useState(ModalStates.CLOSED);

    const [query, setQuery] = useState("");
    const queryFilter = useQueryFilter(query);

    const footerProps = {
        note,
        isUnsaved,
        setModalState,
        failure,
    };

    const footerRef = useRef<HTMLElement>();

    const deleteNoteHandler = async () => {
        await deleteNote(note);
        setModalState(ModalStates.CLOSED);
        navigate(getNewNoteUrl(code));
    };

    const mdeEvents = useMemo(() => {
        return {
            cursorActivity: (instance: Editor) => {
                if (!!!footerRef.current) {
                    return;
                }
                const cursorBounds = instance.cursorCoords(false, "window");
                const footerBounds = footerRef.current?.getBoundingClientRect();

                const cursorBottom = cursorBounds.bottom;
                const curserHeight = cursorBottom - cursorBounds.top;
                const footerTop = footerBounds.top;
                if (cursorBottom > footerTop - curserHeight) {
                    window.scrollBy(0, curserHeight * 6);
                }
            },
        } as SimpleMdeToCodemirrorEvents;
    }, []);

    useHotkeys(
        "ctrl+s, cmd+s",
        (e: KeyboardEvent) => {
            allwaysSave.current();
            e.preventDefault();
        },
        { enableOnTags: ["INPUT", "TEXTAREA", "SELECT"] },
    );
    useHotkeys(
        "ctrl+n, cmd+n",
        (e: KeyboardEvent) => {
            e.preventDefault();
            navigate(getNewNoteUrl(code));
        },
        { enableOnTags: ["INPUT", "TEXTAREA", "SELECT"] },
    );

    return (
        <div className="flex">
            <div className="mw5">
                <input
                    autoComplete="off"
                    name="query"
                    placeholder='search (ex. "/dd" "first post")'
                    className="input-reset ba b--black-20 pa2 mb2 ml2 db w-100"
                    type={"text"}
                    onChange={(e) => setQuery(e.currentTarget.value)}
                    value={query}
                    maxLength={128}
                />
                {notesByDate?.map((nbd: IByDate) => {
                    return <NoteSidebar key={nbd.date} code={code} {...nbd} filter={queryFilter} />;
                })}
            </div>
            <div className="w-100">
                <form onSubmit={save} className={"dark-gray w-100 w-75-l center"}>
                    <input
                        autoComplete="off"
                        name="name"
                        ref={nameRef}
                        placeholder="Title"
                        className="input-reset ba b--black-20 pa2 mb2 db w-100"
                        type={"text"}
                        onChange={(e) => setName(e.currentTarget.value)}
                        value={name}
                        maxLength={128}
                    />

                    <SimpleMDEEditor value={markdown} onChange={setMarkdown} events={mdeEvents} />

                    <div ref={footerRef as any} className={cx("bg-white bottom-0")} style={{ position: "sticky" }}>
                        <EditorFooter {...footerProps} />
                    </div>

                    <Modal isOpen={modalState === ModalStates.SHOW_NOTE_VERSIONS} ariaHideApp={false}>
                        <NoteVersionModal noteId={note.id} close={() => setModalState(ModalStates.CLOSED)} />
                    </Modal>
                    <Modal isOpen={modalState === ModalStates.DELETE} ariaHideApp={false}>
                        <p>Are you sure you want to delete this note? This cannot be undone.</p>
                        <input
                            className="b ph3 pv2 input-reset ba b--red bg-light-red grow pointer f6 dib mr1"
                            type="button"
                            value="DELETE NOTE"
                            onClick={deleteNoteHandler}
                        />
                        <input
                            className={style.button}
                            type="button"
                            value="Cancel"
                            onClick={() => {
                                setModalState(ModalStates.CLOSED);
                            }}
                        />
                    </Modal>
                </form>
            </div>
        </div>
    );
};

const style = {
    button: "b ph3 pv2 input-reset ba b--light-gray black-40 bg-transparent grow pointer f6 dib mr1 right",
};

const EditorFooter = ({
    containerRef,
    note,
    isUnsaved,
    setModalState,
    failure,
}: {
    containerRef?: React.MutableRefObject<any>;
    note: Partial<Note>;
    isUnsaved: () => boolean;
    setModalState: (s: ModalStates) => void;
    failure: { count: number; message: string };
}) => {
    const [advancedOpen, setAdvancedOpen] = useState(false);

    const lastSavedText = useLastSavedText(note);

    return (
        <div ref={containerRef} className="pb5">
            <input
                className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib mr1"
                type="submit"
                value="Save"
            />
            {note.id && (
                <Dropdown label="Advanced" isOpen={advancedOpen} onClick={() => setAdvancedOpen(!!!advancedOpen)}>
                    <input
                        className={style.button}
                        type="button"
                        value="Show Note Versions"
                        onClick={() => setModalState(ModalStates.SHOW_NOTE_VERSIONS)}
                    />
                    <input
                        className={style.button}
                        type="button"
                        value="Delete"
                        onClick={() => setModalState(ModalStates.DELETE)}
                    />
                </Dropdown>
            )}
            {isUnsaved() && <small className="f6 black-60 di ma1">Unsaved.</small>}
            {note && note.updatedAt && <small className="f6 black-60 di ma1">{lastSavedText}</small>}
            {failure.count === 1 && (
                <small className="f6 black-60 di ma1">
                    Something went wrong and we couldn&rsquo;t save. Try again.
                </small>
            )}
            {failure.count > 1 && (
                <small className="f6 black-60 di ma1">
                    Trying again didn&rsquo;t help. Please shoot us an email at hello@ctoscano.com. Failure Message:{" "}
                    <pre>{failure.message}</pre>
                </small>
            )}
        </div>
    );
};

const useLastSavedText = (note?: Partial<Note>) => {
    const [ago, setAgo] = useState("");
    const [lastTimer, setLastTimer] = useState(null);
    const updateAgo = () => {
        const updatedAt = moment.utc(note.updatedAt);
        setAgo(updatedAt.fromNow());
        return (updatedAt.diff as () => number)() * -1;
    };

    useEffect(() => {
        const diff = updateAgo();
        console.log("set timer for ", diff / 1000, " seconds from now", note.updatedAt);
        const timer = setTimeout(() => {
            console.log("ran timer", timer);
            updateAgo();
            setLastTimer(timer);
        }, Math.max(diff / 5, 10 * 1000));

        return () => clearTimeout(timer);
    }, [note, note?.updatedAt, lastTimer]);

    // const ago = moment.utc(note.updatedAt).fromNow();
    return `Last saved ${ago}`;
};

const Dropdown: React.FC<{ label: string; isOpen: boolean; onClick: () => void }> = (props) => {
    return (
        <div className="di relative">
            <a href={null} className="f6 black-60 di ma1 pointer" onClick={props.onClick}>
                {props.label}
            </a>
            {props.isOpen && (
                <div className="absolute left-0 bg-white" style={{ top: "-400%" }} onClick={props.onClick}>
                    {props.children}
                </div>
            )}
        </div>
    );
};

const NoteVersionModal = (props: { noteId: number; close: () => void }) => {
    const { data } = useNoteVersionsQuery({
        variables: {
            noteId: props.noteId,
        },
    });
    const noteVersions = data?.noteVersions?.nodes || [];

    const [selectedVersion, setSelectedVersion] = useState(null);

    const [tab, setTab] = useState(0);
    const showFullText = selectedVersion?.payload?.md || (!!selectedVersion && tab !== 0);

    return (
        <div className="flex">
            <div>
                {noteVersions.map((nV, i) => (
                    <NoteVersionSidebar
                        key={nV.id}
                        noteVersion={nV}
                        previousNoteVersion={noteVersions[i - 1]}
                        onClick={() => setSelectedVersion(nV)}
                        selected={nV === selectedVersion}
                    />
                ))}
            </div>
            <div style={{ flexGrow: 1 }}>
                <div className="flex justify-end">
                    <div
                        onClick={() => setTab(0)}
                        className={cx("pa2 pointer", { b: !!!showFullText && selectedVersion })}>
                        Patch
                    </div>
                    <div onClick={() => setTab(selectedVersion?.id)} className={cx("pa2 pointer", { b: showFullText })}>
                        Full Text
                    </div>
                    <div onClick={props.close} className="pointer pa2">
                        Close
                    </div>
                </div>
                {selectedVersion && !!!showFullText && (
                    <textarea
                        value={selectedVersion.payload.md || selectedVersion.payload.mdPatch}
                        className="vh-75 w-100 nowrap"
                        readOnly
                        style={{ whiteSpace: "pre-line" }}
                    />
                )}
                {showFullText && <NoteAtVersion noteId={selectedVersion.noteId} noteVersionId={selectedVersion.id} />}
            </div>
        </div>
    );
};

const getHunksSummary = (hunks: { newLines: number; oldLines: number }[]) => {
    let additions = 0,
        subtractions = 0;
    hunks.map((h) => {
        additions += h.newLines;
        subtractions += h.oldLines;
    });
    return { additions, subtractions };
};

const NoteVersionSidebar = (props: {
    noteVersion: Partial<NoteVersion>;
    previousNoteVersion: Partial<NoteVersion>;
    onClick: () => void;
    selected?: boolean;
}) => {
    let dateHeader;
    if (
        !!!props.previousNoteVersion ||
        getLocalDate(props.noteVersion.createdAt) !== getLocalDate(props.previousNoteVersion.createdAt)
    ) {
        dateHeader = <div className="f6 bold pt2">{getLocalDate(props.noteVersion.createdAt)}</div>;
    }

    const fullText = get(props.noteVersion, "payload.md", null);
    const hunks = get(props.noteVersion, "payload.mdPatchObj[0].hunks", []);
    const summary = getHunksSummary(hunks);

    return (
        <React.Fragment>
            {dateHeader}
            <div
                key={props.noteVersion.id}
                className={cx("ph3 pv2 pointer", { b: props.selected })}
                onClick={props.onClick}>
                <span>{getLocalTime(props.noteVersion.createdAt)}</span>
                {fullText && <span className="pl2">Full text</span>}
                {!!!fullText && <span className="green pl2">+{summary.additions}</span>}
                {!!!fullText && <span className="red pl2">-{summary.subtractions}</span>}
            </div>
        </React.Fragment>
    );
};

const NoteAtVersion = (props: { noteId: number; noteVersionId: number }) => {
    const { data } = useNoteAtVersionQuery({ variables: { id: props.noteId, versionId: props.noteVersionId } });
    const selectedVersion = data?.note?.atVersion;
    return (
        <textarea
            value={selectedVersion?.payload?.md}
            className="vh-75 w-100 nowrap"
            readOnly
            style={{ whiteSpace: "pre-line" }}
        />
    );
};

const NoteSidebar = (props: {
    code?: string;
    date: string;
    notes: Partial<Note>[];
    filter: (note: Note) => Partial<Note>;
}) => {
    const { code } = props;
    const dateHeader = <div className="f6 black-60 pt2 pl4">{props.date}</div>;
    return (
        <React.Fragment>
            {dateHeader}
            {props.notes?.map((n: Note) => {
                if (!!!props.filter(n)) {
                    return;
                }
                return (
                    <NoteRow
                        key={n.id}
                        note={n}
                        urlCreate={() => ({ pathname: "", search: "" })}
                        urlMain={(noteId: number) => getNoteUrl({ code, id: noteId })}
                        urlEdit={() => ({ pathname: "", search: "" })}
                    />
                );
            })}
        </React.Fragment>
    );
};
