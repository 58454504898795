import { useSuperApolloClient } from "../../shared/client";
import {
    useAllUsersLazyQuery,
    useOrganizationsLazyQuery,
    useUpdateUserPasswordMutation,
} from "../../shared/models/types";

export const useSuperUsersLazy = (superJwt: string) => {
    const superApolloClient = useSuperApolloClient(superJwt);

    return useAllUsersLazyQuery({
        client: superApolloClient,
    });
};

export const userSuperUpdateUserPasswordMutation = (superJwt: string) => {
    const superApolloClient = useSuperApolloClient(superJwt);
    return useUpdateUserPasswordMutation({
        client: superApolloClient,
    });
};

export const useSuperOrganizationsLazy = (superJwt: string) => {
    const superApolloClient = useSuperApolloClient(superJwt);

    return useOrganizationsLazyQuery({
        client: superApolloClient,
    });
};
