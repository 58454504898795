import * as React from "react";
const { useState, useRef } = React;

import cx from "classnames";
import { useNavigate, useParams } from "react-router-dom";

import { ITokenDetails } from "../../shared/graphql/organizations";
import {
    TokenStateEnum,
    useApplyTokenMutation,
    useValidateTokenQuery,
    ValidateTokenQuery,
} from "../../shared/models/types";
import { passthrough, TextFormField } from "../shared/components/form";
import { OrganizationLocation } from "../shared/routes/locations";
import { currentUserVar, ICurrentUser } from "../../shared/services/auth.data";

enum PageState {
    ShowRegistration,
    ShowSignIn,
    ShowOrgSetup,
}

const getCurrentPageState = (currentUser: ICurrentUser, alreadyRegistered: boolean) => {
    if (currentUser === null) {
        if (!!!alreadyRegistered) {
            return PageState.ShowRegistration;
        } else {
            return PageState.ShowSignIn;
        }
    } else {
        return PageState.ShowOrgSetup;
    }
};

const translateError = (message: string) => {
    if (message.indexOf("IDX_366e3fd58e1379ed9e34f4dad6") >= 0) {
        return "That URL already exists. Try another.";
    }
    return message;
};

export const MembershipInvitationPage = () => {
    const { code, key } = useParams();
    const navigateTo = useNavigate();

    const [alreadyRegistered, setAlreadyRegistered] = useState(false);

    const currentUser = currentUserVar();

    const [invitationDetails, setInvitationDetails] = useState<ITokenDetails>({});
    const nameRef = useRef(null);

    const { loading: loadingToken, data: tokenData } = useValidateTokenQuery({
        variables: { key },
        onCompleted: (data: ValidateTokenQuery) => {
            if (!!!tokenData.validateToken) {
                // Token was not found
            } else if (tokenData.validateToken.state === TokenStateEnum.Disabled) {
                // Token disabled
            } else {
                setInvitationDetails(tokenData.validateToken.details);
            }
        },
    });

    const currentPageState = getCurrentPageState(currentUser, alreadyRegistered);

    const [applyToken, { loading, error }] = useApplyTokenMutation();
    const acceptMembershipInvitation = async (event?: React.FormEvent<HTMLFormElement>) => {
        if (event) {
            event.preventDefault();
        }
        const { data } = await applyToken({
            variables: {
                key,
                details: invitationDetails,
            },
        });

        navigateTo(OrganizationLocation.toUrl({ code: data.applyToken.organization.url }));
    };

    return (
        <div>
            <article className={"dark-gray w-100 w-75-l center"}>
                <div className={"w-100 pa2 ph3-ns pb3-ns"}>
                    <div className="w-100">
                        {PageState.ShowRegistration === currentPageState && (
                            <form onSubmit={() => null}>
                                <p>reg</p>
                                <p onClick={() => setAlreadyRegistered(true)}>Already registered?</p>
                            </form>
                        )}
                        {PageState.ShowSignIn === currentPageState && (
                            <form onSubmit={() => null}>
                                <p>sign in </p>
                                <p onClick={() => setAlreadyRegistered(false)}>Not registered?</p>
                            </form>
                        )}
                        {PageState.ShowOrgSetup === currentPageState && (
                            <form
                                onSubmit={acceptMembershipInvitation}
                                className={cx("dark-gray w-100 w-75-l center", {
                                    hide: !!!currentUser,
                                })}>
                                <TextFormField
                                    name="name"
                                    value={invitationDetails.name}
                                    placeholder="Display Name"
                                    inputRef={nameRef}
                                    onChange={passthrough(invitationDetails, setInvitationDetails)}
                                    maxLength={50}
                                    disabled={invitationDetails.editable !== true}
                                />
                                <input
                                    className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib mr1"
                                    type="submit"
                                    value={`Accept Invitation`}
                                />
                                {error && <p className="red">{translateError(error.message)}</p>}
                            </form>
                        )}
                    </div>
                </div>
            </article>
        </div>
    );
};
