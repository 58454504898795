import * as React from "react";
import { Link } from "react-router-dom";

const heroImage = require("../shared/images/desk.jpg");

export const LongformHomepage = () => {
    return (
        <div>
            <article className={"dark-gray w-100 w-75-l center"}>
                <div className={"w-100 pa2 ph3-ns pb3-ns"}>
                    <div className={"dt fl w-50"}>
                        <h1 className={"f2 mv0 pv2 ph3"}>Longform</h1>
                        <p className={"f6 lh-copy measure mt2 mid-gray pv2 ph3"}>
                            Some things are worth saying slowly.
                        </p>
                        <p className={"f6 lh-copy measure mt2 mid-gray pv2 ph3"}>
                            What happens when we want to say you want to talk about something but it doesn&rsquo;t fit
                            into a text message? What happens when you want to have a conversation about something
                            important, but it might take you a couple of minutes to talk it out.
                        </p>
                        <p className={"f6 lh-copy measure mt2 mid-gray pv2 ph3"}>
                            Longform is a tool to practice writing as a skill, talent, and habit. We&rsquo;ll use
                            letters to reinforce memories, explore ideas, reflect, and set intentions we can share with
                            friends and loved ones.
                        </p>
                    </div>
                    <div className={"dt fl w-50 ph3"}>
                        <img src={heroImage} className={"db w-100 br2"} alt="A place for writing." />
                    </div>
                </div>
            </article>
        </div>
    );
};
