import { gql } from "@apollo/client";

export const AuthenticateUser = gql`
    mutation AuthenticateUser($username: String!, $password: String!, $role: String) {
        authenticateUser(input: { username: $username, password: $password, role: $role }) {
            jwt
            user {
                id
                username
            }
        }
    }
`;

export const RegisterUser = gql`
    mutation RegisterUser($username: String!, $password: String!) {
        registerUser(input: { username: $username, password: $password }) {
            jwt
            user {
                id
                username
            }
        }
    }
`;
