import * as React from "react";
const { useState, useRef } = React;
import { find } from "lodash";
import * as Modal from "react-modal";
import { useParams } from "react-router-dom";

import { Checkbox } from "../../shared/components/Form/Checkbox";
import {
    IMembershipDetails,
    ITokenDetails,
    MembershipStateEnum,
} from "../../shared/graphql/organizations";
import {
    Maybe,
    Membership,
    OrgSettingsQuery,
    Token,
    TokenStateEnum,
    TokenTypeEnum,
    useDisableTokenMutation,
    useGenerateTokenMutation,
    useOrgSettingsQuery,
    useUpdateMembershipMutation,
} from "../../shared/models/types";
import { toDate } from "../../shared/utils/formatting";
import { passthrough, passthroughBoolean, TextFormField } from "../shared/components/form";
import { InvitationPage } from "../shared/routes/locations";

const createInvitationHref = (
    token: Partial<Token>,
    organization: OrgSettingsQuery["organizationByUrl"],
    location = (window as any).location as Location,
) => {
    return [
        location.protocol,
        "//",
        location.host,
        location.pathname,
        "#",
        InvitationPage.toUrl({ key: token.key, code: organization.url }),
    ].join("");
};

const MembershipTokenInvitation = (props: {
    token: Partial<Token>;
    organization: OrgSettingsQuery["organizationByUrl"];
}) => {
    const url = createInvitationHref(props.token, props.organization);
    return (
        <div>
            <p>Click on this link to join: {url}</p>
        </div>
    );
};

enum ModalStates {
    CLOSED,
    DISABLE_INVITATION,
    DISABLE_MEMBERSHIP,
    CHANGE_DISPLAY_NAME,
}

export const OrgSettingsPage = () => {
    const { id, code } = useParams();
    const [tokenDetails, setTokenDetails] = useState<ITokenDetails>({});
    const [newToken, setNewToken] = useState<Maybe<Token>>(null);
    const nameRef = useRef(null);

    const [modalState, setModalState] = useState(ModalStates.CLOSED);

    const { data: settingsData, loading, refetch: refetchSettings } = useOrgSettingsQuery({
        variables: { url: code },
    });
    const organization = settingsData?.organizationByUrl;
    const tokens = organization?.tokensByOrgId?.nodes;
    const memberships = organization?.membershipsByOrgId?.nodes;
    const [generateToken] = useGenerateTokenMutation();

    const createToken = async (event?: React.FormEvent<HTMLFormElement>) => {
        if (event) {
            event.preventDefault();
        }
        const {
            data: {
                generateToken: { token },
            },
        } = await generateToken({
            variables: {
                type: TokenTypeEnum.Membership,
                details: tokenDetails,
                orgId: organization.id,
            },
        });
        setTokenDetails({});
        setNewToken(token as Token);
        refetchSettings();
    };

    // Disable Invitation
    const [selectedTokenToDisable, setSelectedTokenToDisable] = useState(null);
    const setSelectedTokenIdToDisable = (tid: number) => {
        setSelectedTokenToDisable(find(tokens, { id: tid }));
    };
    const [disableTokenMutation] = useDisableTokenMutation();
    const disableToken = async (token: Token) => {
        await disableTokenMutation({
            variables: {
                key: token.key,
            },
        });
        refetchSettings();
    };
    // Disable Membership
    const [updateMembership] = useUpdateMembershipMutation();
    const [selectedMembershipToDisable, setSelectedMembershipToDisable] = useState<
        Partial<Membership>
    >(null);
    const setSelectedMembershipByIdToDisable = (mid: number) => {
        setSelectedMembershipToDisable(find(memberships, { id: mid }));
    };
    const disableMembership = async (
        m: Partial<Membership>,
        state = MembershipStateEnum.DISABLED,
    ) => {
        await updateMembership({
            variables: {
                id: m.id,
                state,
            },
        });
        refetchSettings();
    };
    // Change Display Name
    const [selectedMembershipToChangeName, setSelectedMembershipByToChangeName] = useState<
        Partial<Membership>
    >(null);
    const [memberNameChangeDetails, setMemberNameChangeDetails] = useState<
        IMembershipDetails
    >({});
    const setSelectedMembershipByIdToChangeName = (mid: number) => {
        const targetMembership = find(memberships, { id: mid });
        setMemberNameChangeDetails({ ...targetMembership.payload });

        setSelectedMembershipByToChangeName(targetMembership);
    };
    const changeDisplayName = async (event?: React.FormEvent<HTMLFormElement>) => {
        if (event) {
            event.preventDefault();
        }
        await updateMembership({
            variables: {
                id: selectedMembershipToChangeName.id,
                payload: memberNameChangeDetails,
            },
        });
        refetchSettings();
    };

    if (!!!organization) {
        if (loading) {
            return <h2>Loading...</h2>;
        } else {
            return <h2>You do not have access to this page.</h2>;
        }
    }
    return (
        <div>
            <article className={"dark-gray w-100 w-75-l center"}>
                <div className={"w-100 pa2 ph3-ns pb3-ns"}>
                    <div className="w-100">
                        Settings
                        <h2>{organization.name}</h2>
                    </div>
                </div>
                <div className={"w-100 pa2 ph3-ns pb3-ns"}>
                    <div className="w-100">
                        <form
                            onSubmit={createToken}
                            className={"dark-gray w-100 w-75-l center"}>
                            <TextFormField
                                name="name"
                                value={tokenDetails.name}
                                placeholder="Display Name"
                                inputRef={nameRef}
                                onChange={passthrough(tokenDetails, setTokenDetails)}
                                maxLength={50}
                            />
                            <Checkbox
                                label="Editable"
                                name="editable"
                                checked={!!tokenDetails.editable}
                                onChange={passthroughBoolean(tokenDetails, setTokenDetails)}
                            />
                            <input
                                className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib mr1"
                                type="submit"
                                value="Create Author Invitation"
                            />
                        </form>
                        {newToken && (
                            <MembershipTokenInvitation
                                token={newToken}
                                organization={organization}
                            />
                        )}
                    </div>
                </div>
                <div className={"w-100 pa2 ph3-ns pb3-ns"}>
                    <div className="w-100">
                        Invitations
                        <table>
                            <tbody>
                                {tokens.map((t, i) => {
                                    return (
                                        <tr key={`t${t.id}`}>
                                            <td className="pa2">{t.details.name}</td>
                                            <td className="pa2">{t.state}</td>
                                            <td className="pa2">{toDate(t.createdAt)}</td>
                                            <td className="pa2">
                                                {t.createdAt !== t.updatedAt
                                                    ? toDate(t.updatedAt)
                                                    : ""}
                                            </td>
                                            <td className="pa2">{t.key}</td>
                                            <td className="pa2">
                                                (
                                                <a
                                                    className={"link blue underline"}
                                                    href={createInvitationHref(
                                                        t,
                                                        organization,
                                                    )}>
                                                    link
                                                </a>
                                                )
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div>
                            <input
                                className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib mr1"
                                type="button"
                                value="Disable Invitations"
                                onClick={() =>
                                    setModalState(ModalStates.DISABLE_INVITATION)
                                }
                            />
                        </div>
                    </div>
                    {/* Disable Invitations Modal */}
                    <Modal
                        isOpen={modalState === ModalStates.DISABLE_INVITATION}
                        ariaHideApp={false}>
                        <select
                            onChange={(e) =>
                                setSelectedTokenIdToDisable(parseInt(e.target.value, 10))
                            }>
                            <option>Select below</option>
                            {tokens.map((t, i) => {
                                if (t.state !== TokenStateEnum.New) {
                                    return;
                                }
                                return (
                                    <option key={`tm${t.id}`} value={t.id}>
                                        {t.details?.name} ({t.type})
                                    </option>
                                );
                            })}
                        </select>
                        <input
                            className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib mr1"
                            type="button"
                            value="Disable Invitation"
                            disabled={!!!selectedTokenToDisable}
                            onClick={async () => {
                                await disableToken(selectedTokenToDisable);
                            }}
                        />
                        <input
                            className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib mr1"
                            type="button"
                            value="Cancel"
                            onClick={() => setModalState(ModalStates.CLOSED)}
                        />
                    </Modal>
                </div>
                <div className={"w-100 pa2 ph3-ns pb3-ns"}>
                    <div className="w-100">
                        Members
                        <table>
                            <tbody>
                                {memberships.map((m, i) => {
                                    return (
                                        <tr key={`m${m.id}`}>
                                            <td className="pa2">{m.payload.name}</td>
                                            <td className="pa2">{m.type}</td>
                                            <td className="pa2">{m.state}</td>
                                            <td className="pa2">
                                                {m.userId === organization.ownerId &&
                                                    "Owner"}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div>
                            <input
                                className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib mr1"
                                type="button"
                                value="Disable Membership"
                                onClick={() =>
                                    setModalState(ModalStates.DISABLE_MEMBERSHIP)
                                }
                            />
                            <input
                                className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib mr1"
                                type="button"
                                value="Change Display Name"
                                onClick={() =>
                                    setModalState(ModalStates.CHANGE_DISPLAY_NAME)
                                }
                            />
                        </div>
                        {/* Disable Membership Modal */}
                        <Modal
                            isOpen={modalState === ModalStates.DISABLE_MEMBERSHIP}
                            ariaHideApp={false}>
                            <select
                                onChange={(e) =>
                                    setSelectedMembershipByIdToDisable(
                                        parseInt(e.target.value, 10),
                                    )
                                }>
                                <option>Select below</option>
                                {memberships.map((m, i) => {
                                    if (m.userId === organization.ownerId) {
                                        return;
                                    }
                                    return (
                                        <option key={`md${m.id}`} value={m.id}>
                                            ID:{m.userId} - {m.payload?.name} ({m.type})
                                        </option>
                                    );
                                })}
                            </select>
                            <input
                                className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib mr1"
                                type="button"
                                value="Disable Membership"
                                disabled={
                                    !!!selectedMembershipToDisable ||
                                    selectedMembershipToDisable.state ===
                                        MembershipStateEnum.DISABLED
                                }
                                onClick={async () => {
                                    await disableMembership(selectedMembershipToDisable);
                                }}
                            />
                            <input
                                className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib mr1"
                                type="button"
                                value="Reactivate Membership"
                                disabled={
                                    !!!selectedMembershipToDisable ||
                                    selectedMembershipToDisable.state ===
                                        MembershipStateEnum.ACTIVE
                                }
                                onClick={async () => {
                                    await disableMembership(
                                        selectedMembershipToDisable,
                                        MembershipStateEnum.ACTIVE,
                                    );
                                }}
                            />
                            <input
                                className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib mr1"
                                type="button"
                                value="Cancel"
                                onClick={() => setModalState(ModalStates.CLOSED)}
                            />
                        </Modal>
                        {/* Change Display Name Modal */}
                        <Modal
                            isOpen={modalState === ModalStates.CHANGE_DISPLAY_NAME}
                            ariaHideApp={false}>
                            <form
                                onSubmit={changeDisplayName}
                                className={"dark-gray w-100 w-75-l center"}>
                                <TextFormField
                                    name="name"
                                    value={memberNameChangeDetails.name}
                                    placeholder="Display Name"
                                    inputRef={nameRef}
                                    onChange={passthrough(
                                        memberNameChangeDetails,
                                        setMemberNameChangeDetails,
                                    )}
                                    maxLength={50}
                                />
                                <select
                                    onChange={(e) =>
                                        setSelectedMembershipByIdToChangeName(
                                            parseInt(e.target.value, 10),
                                        )
                                    }>
                                    <option>Select below</option>
                                    {memberships.map((m, i) => {
                                        if (m.userId === organization.ownerId) {
                                            return;
                                        }
                                        return (
                                            <option key={`md${m.id}`} value={m.id}>
                                                ID:{m.userId} - {m.payload?.name} ({m.type})
                                            </option>
                                        );
                                    })}
                                </select>
                                <input
                                    className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib mr1"
                                    type="submit"
                                    value="Change Displayname"
                                    disabled={!!!setSelectedMembershipByIdToChangeName}
                                />
                                <input
                                    className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib mr1"
                                    type="button"
                                    value="Cancel"
                                    onClick={() => setModalState(ModalStates.CLOSED)}
                                />
                            </form>
                        </Modal>
                    </div>
                </div>
            </article>
        </div>
    );
};
