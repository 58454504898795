import { gql } from "@apollo/client";
import { OrganizationProgramsQuery } from "../models/types";

export type PartialOrganization = Partial<OrganizationProgramsQuery["organizationByUrl"]>;

export const GenerateToken = gql`
    mutation GenerateToken($type: String!, $details: JSON!, $orgId: Int) {
        generateToken(input: { type: $type, details: $details, orgId: $orgId }) {
            token {
                nodeId
                id
                type
                key
                state
                details
                org {
                    nodeId
                    name
                    url
                }
                createdAt
                updatedAt
            }
        }
    }
`;

export const ValidateToken = gql`
    query ValidateToken($key: String!) {
        validateToken(key: $key) {
            nodeId
            id
            type
            key
            state
            details
            org {
                nodeId
                name
                url
            }
            createdAt
            updatedAt
        }
    }
`;

export const DisableToken = gql`
    mutation DisableToken($key: String!) {
        disableToken(input: { key: $key }) {
            token {
                nodeId
                id
                type
                key
                state
                details
                org {
                    nodeId
                    name
                    url
                }
                createdAt
                updatedAt
            }
        }
    }
`;

export interface ITokenDetails {
    editable?: boolean;
    name?: string;
    url?: string;
}

export const ApplyToken = gql`
    mutation ApplyToken($key: String!, $details: JSON!) {
        applyToken(input: { key: $key, details: $details }) {
            organization {
                nodeId
                id
                type
                name
                url
                createdAt
                updatedAt
            }
        }
    }
`;

export const OrganizationsNotes = gql`
    query OrganizationsNotes($url: String) {
        organizations(orderBy: UPDATED_AT_DESC, condition: { url: $url }) {
            nodes {
                id
                name
                url
                notes: notesByOrgId(orderBy: UPDATED_AT_DESC) {
                    nodes {
                        __typename
                        id
                        userId
                        type
                        name
                        slug
                        payload
                        meta
                        isPublic
                        createdAt
                        updatedAt
                    }
                }
            }
        }
    }
`;

export const OrgSettings = gql`
    query OrgSettings($url: String!) {
        organizationByUrl(url: $url) {
            id
            name
            url
            ownerId
            tokensByOrgId {
                nodes {
                    id
                    key
                    type
                    state
                    details
                    createdAt
                    updatedAt
                    grantedById
                }
            }
            membershipsByOrgId {
                nodes {
                    __typename
                    id
                    userId
                    type
                    state
                    payload
                    createdAt
                    updatedAt
                }
            }
        }
    }
`;

export interface IMembershipDetails {
    name?: string;
}

export enum MembershipStateEnum {
    ACTIVE = "active",
    DISABLED = "disabled",
}

export const UpdateMembership = gql`
    mutation UpdateMembership($id: Int!, $type: String, $state: String, $payload: JSON) {
        updateMembership(input: { id: $id, patch: { id: $id, type: $type, state: $state, payload: $payload } }) {
            membership {
                __typename
                id
                type
                state
                payload
                createdAt
                updatedAt
            }
        }
    }
`;
