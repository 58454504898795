export { MutationAuthenticateUserArgs, User, AuthenticateUserInput } from "../../../shared/models/types";

import { FetchResult, MutationResult, useMutation } from "@apollo/client";

import {
    AuthenticateUserInput,
    AuthenticateUserMutation,
    RegisterUserInput,
    RegisterUserMutation,
    User,
} from "../../../shared/models/types";
import { saveSession } from "../../services/auth";
import { AuthenticateUser, RegisterUser } from "./query.gql";

// For Component
export type UserInput = RegisterUserInput;

// Register User
export interface IRegisterProps {
    register: (userInput: UserInput) => Promise<User>;
}

// Get User

// Authenticate User

export interface IAuthProps {
    jwt?: string;
    authenticate: (inputs: { username: string; password: string }) => Promise<any>;
}

export declare type CustomMutationTuple<TData, TVariables> = [
    (inputs: TVariables) => Promise<FetchResult<TData>>,
    MutationResult<TData>,
];

export const useAuthenticate = (
    skipSaveSession = false,
): CustomMutationTuple<AuthenticateUserMutation, AuthenticateUserInput> => {
    const [mutate, mutationResult] = useMutation<AuthenticateUserMutation, AuthenticateUserInput>(AuthenticateUser);
    return [
        async (inputs: { username: string; password: string }) => {
            return mutate({
                update: (cache, { data, errors }: { data: AuthenticateUserMutation; errors: any[] }) => {
                    if (!data.authenticateUser || !data.authenticateUser.jwt) {
                        return;
                    }
                    if (!!!skipSaveSession) {
                        saveSession(data.authenticateUser.user.username, data.authenticateUser.jwt, cache);
                    }
                },
                variables: {
                    ...inputs,
                },
            });
        },
        mutationResult,
    ];
};

export const useSignup = () => {
    const [mutation] = useMutation<RegisterUserMutation, RegisterUserInput>(RegisterUser);
    const userSignup = (variables: RegisterUserInput) => {
        return mutation({
            // errorPolicy: "all",
            update: (cache, { data, errors }: { data: RegisterUserMutation; errors: any }) => {
                if (!data.registerUser) {
                    return;
                }
                saveSession(data.registerUser.user.username, data.registerUser.jwt, cache);
            },
            variables,
        });
    };
    return userSignup;
};
